<template>
    <div class="visit-page pb-3">
        <div class="container" v-html="page.content" />
        <!-- <div class="container">
            <div class="visit__title">ПОЕЗДКИ НА&nbsp;ПРОИЗВОДСТВЕННЫЕ <br /> ПЛОЩАДКИ ООО &laquo;ЛЛК-ИНТЕРНЕШНЛ&raquo;
            </div>
            <div class="visit__description">Более подробно с&nbsp;процессом организации поездки можно ознакомиться
                <a href="/" class="visit__link">здесь</a>
            </div>
            <div class="visit__items-container">
                <div class="visit__item">
                    <div class="visit__item-img">
                        <img src="../../assets/images/visit/perm.png" alt="" />
                    </div>
                    <div class="visit__item-content">
                        <div class="visit__item-content-title">
                            ТПП ООО &laquo;ЛЛК-ИНТЕРНЕШНЛ&raquo; Г. ПЕРМЬ
                        </div>
                        <a href="/" class="visit__item-content-link">
                            подробнее
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 2.79166L6.66667 5.99999L3.75 9.20833" stroke="#D2233C"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.75 2.79166L9.66667 5.99999L6.75 9.20833" stroke="#D2233C"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a>
                        <div class="visit__item-button-container">
                            <a href="/" class="visit__item-button">
                                ОФОРМИТЬ ЗАЯВКУ
                            </a>
                        </div>
                    </div>
                </div>
                <div class="visit__item">
                    <div class="visit__item-img">
                        <img src="../../assets/images/visit/volgograd.png" alt="" />
                    </div>
                    <div class="visit__item-content">
                        <div class="visit__item-content-title">
                            ПОЕЗДКА НА&nbsp;ТПП ООО &laquo;ЛЛК-ИНТЕРНЕШНЛ&raquo; И&nbsp;ЗАВОД &laquo;ИНТЕСМО&raquo; Г.
                            ВОЛГОГРАД
                        </div>
                        <a href="/" class="visit__item-content-link">
                            подробнее
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 2.79166L6.66667 5.99999L3.75 9.20833" stroke="#D2233C"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.75 2.79166L9.66667 5.99999L6.75 9.20833" stroke="#D2233C"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a>
                        <div class="visit__item-button-container">
                            <a href="/" class="visit__item-button">
                                ОФОРМИТЬ ЗАЯВКУ
                            </a>
                        </div>
                    </div>
                </div>
                <div class="visit__item">
                    <div class="visit__item-img">
                        <img src="../../assets/images/visit/kz.png" alt="" />
                    </div>
                    <div class="visit__item-content">
                        <div class="visit__item-content-title">
                            ПОЕЗДКА НА&nbsp;ТПП ТОО &laquo;ЛУКОЙЛ Лубрикантс Центральная Азия&raquo; КАЗАХСТАН
                        </div>
                        <a href="/" class="visit__item-content-link">
                            подробнее
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 2.79166L6.66667 5.99999L3.75 9.20833" stroke="#D2233C"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.75 2.79166L9.66667 5.99999L6.75 9.20833" stroke="#D2233C"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a>
                        <div class="visit__item-button-container">
                            <a href="/" class="visit__item-button">
                                ОФОРМИТЬ ЗАЯВКУ
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { get } from '@/lib/axios';
import $ from "jquery";

export default {
    name: "Visit",
    data: () => ( {
        type: 4,
        page: {},
    } ),
    methods: {
        async fetchData ()
        {
            await get( "static-page/" + this.type ).then( response =>
            {
                this.page = response.data.data;
            } );
            const that = this;

            $( '#perm' ).on( 'click', ( e ) =>
            {
                e.preventDefault();

                that.$router.push( {
                    name: 'VisitRequestForm',
                    params: {
                        slug: 'perm'
                    }
                } )
            } );
            $( '#volgograd' ).on( 'click', ( e ) =>
            {
                e.preventDefault();

                that.$router.push( {
                    name: 'VisitRequestForm',
                    params: {
                        slug: 'torzhok'
                    }
                } )
            } );
          $( '#tyumen' ).on( 'click', ( e ) =>
          {
            e.preventDefault();

            that.$router.push( {
              name: 'VisitRequestForm',
              params: {
                slug: 'tyumen'
              }
            } )
          } );
            $( '#kazaxstan' ).on( 'click', ( e ) =>
            {
                e.preventDefault();

                that.$router.push( {
                    name: 'VisitRequestForm',
                    params: {
                        slug: 'kazakhstan'
                    }
                } )
            } );
        }
    },
    mounted ()
    {
        this.fetchData();
    },
    metaInfo ()
    {
        return {
            title: `Поездки - ЛУКМАРКЕТ`,
        };
    },
}
</script>

<style lang="scss">
.visit-page {
    margin-top: 40px;

    .page-title {
        color: #212529;
        padding-top: 40px;
    }

    .brand-color {
        color: #d2233c;
    }

    .font-size-1rem {
        font-size: 1rem;
    }

    .white-space-nowrap {
        white-space: nowrap;
    }

    .g-horisontal-spacer {
        min-height: 30px;
    }

    .g-resize {
        display: block;
        width: 100%;
        height: auto !important;
    }

    .img-bottom-spacer {
        margin-bottom: 20px;
    }

    .default-text-decoration {
        font: 21px/30px Futuris;
        color: #4a4a4a;
        text-align: justify;
    }

    .default-text-decoration strong {
        font: 21px/30px Futuris;
    }

    .default-text-decoration p {
        padding: 10px 0 20px;
    }

    .g-horisontal-spacer {
        min-height: 30px;
    }

    .default-text-decoration--ileft-right {
        display: block;
    }

    .default-text-decoration--out-pad {
        display: block;
    }

    .default-text-decoration--two-col {
        column-count: 2;
        column-gap: 30px;
        text-align: left;
    }

    .g-horisontal-line {
        display: block;
        border-bottom: solid #dfe1e3 1px;
        margin-top: 56px;
        margin-bottom: 40px;
    }

    .inner-section-title__link,
    .inner-section-title {
        display: block;
        text-transform: uppercase;
        color: #212529;
        font: 700 16px/24px Futuris;
        text-decoration: none !important;
    }

    .perm-section-title {
        margin-bottom: 2rem;
    }

    .default-link {
        display: inline-block;
        color: #d2233c;
        text-decoration: none !important;
    }

    .container-center {
        display: block;
        text-align: center;
    }

    .request-form-btn {
        text-align: center;
        display: inline-block;
        background: #d2233c;
        border-radius: 4px;
        color: #fff;
        text-transform: uppercase;
        font: 700 16px/24px Futuris;
        margin-top: 20px;
        padding: 10px;
        text-decoration: none;

        @media (max-width: 992px) {
            margin: 20px 0 40px 0;
        }

        @media (min-width: 1100px) {
            min-width: 275px;
        }
    }

    .request-form-btn:hover {
        color: #fff;
        text-decoration: none;
    }

    @media (max-width: 768px) {
        .default-text-decoration--two-col {
            column-count: 1;
            column-gap: 0;
        }
    }

}
</style>
